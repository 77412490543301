
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import api from "@/api/index.js";
import axios from "axios";

export default {
    name: "iotinfo",
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
        ...mapGetters("publicInfo", ["iotId"]),
    },
    data() {
        return {
          iotList: {},
        };
    },
    watch: {
      iotId(newType, oldType) {
            this.getIotList();
        },
    },
    mounted() {
      console.log('ok',this.slideShowType)
      setTimeout(()=>{
        this.getIotList();
      },1000)
    },
    methods: {
      ...mapActions("publicInfo", [
        "changeSlideTypeAction",
        "changeIotIdAction"
      ]),
        getIotList() {
          let that = this;
        console.log('请求数据');
        console.log('iotId',that.iotId)
          let params = {
            iotId: that.iotId
          }
          axios.get('/api_btq/getIotDeviceWarningList',{params: params}).then(function (res) {
            console.log('请求数据res', res)
            that.iotList = res.data.data
          })

        },
        closeWindow() {
            let that = this
          console.log(that.slideShowType,'???')
            // that.changeIotIdAction(null);
          if(that.slideShowType === 'iot-lively'){
            that.changeSlideTypeAction("lively");
          }
          if (that.slideShowType === 'iot-safe'){
            that.changeSlideTypeAction("safe");
          }
        },
    },
};
