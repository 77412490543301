
import * as Cesium from "cesium";
import { defined } from "cesium";
import "cesium/Source/Widgets/widgets.css";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import flvjs from "flv.js";
import DPlayer from "dplayer";
import CesiumInit from "@/plugins/cesium/init";
import CesiumValidate from "@/plugins/cesium/validate";
import api from "@/api/index.js";
import axios from "axios";

export default {
  name: "CesiumScene",
  computed: {
    ...mapState(["commonData", "refreshNumber"]),
    ...mapGetters("cesiumInfo", ["cesiumMarkerList", "nowChooseMonomerInfo"]),
    ...mapGetters("publicInfo", [
      "slideAnimationTime",
      "slideShowType",
      "slideTypeIndex",
      "nowOrgId",
      "iotId"
    ]),
    ...mapGetters("config", ["configInfo"]),
  },
  watch: {
    nowOrgId(newId, oldId) {
      console.log(newId);
    },
    doneTime(newTime, oldTime) {
      let _this = this;
      if (newTime == 2) {
        // 3dtiles加载完成
        _this.changeLoadingPageHideAction(true);
      }
    },
    cesiumMarkerList(newList, oldList) {
      let _this = this;
      if (newList.length > 0) {
        _this.addMarker(newList);
      } else {
        this.removeAll();
      }
    },
    slideShowType(newType, oldType) {
      if (
        newType != "monomer_build" &&
        newType != "monomer_unit" &&
        newType != "monomer_house"
      ) {
        // this.removeAll();
      }
      if (newType == "safe") {
        console.log("平安稳定");
        this.areaInit2();
      } else {
        this.hiddenArea();
      }
    },
    slideTypeIndex(newIndex, oldIndex) {
      if (newIndex == -1) {
        this.streetCameraFlyTo(2);
      }
    },
    mouseCtrl(newType, oldType) {
      let _this = this;
      let { cyclePoint, slideShowType } = this;
      if (newType == 2 && slideShowType == "street") {
        var entity = window.cesiumViewer.entities.add({
          position: new Cesium.Cartesian3.fromDegrees(
            // 118.76825207386261,
            // 32.1075984192413
            cyclePoint.longitude,
            cyclePoint.latitude
          ),
          point: {
            // color: Cesium.Color.BLUE,
            color: Cesium.Color.fromCssColorString("#000").withAlpha(0),
            pixelSize: 10,
          },
        });

        let initialHeading = 0;
        // 步长
        let step = 0.1;
        this.mouseCtrl = 0;
        this.setIntervalWithCamera = setInterval(() => {
          if (initialHeading > 360) {
            initialHeading = 0;
          }
          var offset = new Cesium.HeadingPitchRange(
            Cesium.Math.toRadians(initialHeading), // 水平方向的旋转角 0-360度
            -Cesium.Math.toRadians(cyclePoint.rotate), // 垂直平面俯仰角 // 0-90度
            // 300 // 相机距离地球球心的距离
            cyclePoint.height
          );
          window.cesiumViewer.zoomTo(entity, offset);
          initialHeading += step;
        }, step * 1000);
      }

      if (newType == 1) {
        if (this.nowEntityId) {
          this.areaInit().then((res) => {
            _this.areaCameraFlyTo(_this.nowEntityId);
          });
        } else {
          this.streetCameraFlyTo();
        }
      }
    },
    refreshNumber(newNumber, oldNumber) {
      if (newNumber > 0) {
        this.getCommonList();
      }
    },
  },
  data() {
    return {
      doneTime: 0,
      playUrl: "",
      isLiveShow: false,
      unitShow: false,
      modelShow: false,
      mouseCtrl: 1,
      mouseTime: 0,
      monomerList: [],
      createMonomerType: ["build"],
      cyclePoint: {
        longitude: 118.7596037952617,
        latitude: 32.11171961826268,
        height: 5700,
        rotate: 45,
      },
      nowEntityId: null,
      streetEntity: false,
      moduleEntity: false,
      areaListTemp: [],
      areaHas3dtilesList: [],
      areaOrgList: [],
    };
  },
  mounted() {
    this.init();
    this.initMaxTime();
    // this.listenMouseCtrl();

    // this.doneTime = 3;
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations("publicInfo", [
      "changeOrgId",
      "changeOrgInfo",
      "changeSlideTypeIndex",
    ]),
    ...mapActions("publicInfo", [
      "changeLoadingPageHideAction",
      "changeSlideTypeAction",
      "changeSlideRoomShowAction",
      "changeOrgIdAction",
      "changeBuildingIdAction",
      "changeTagTypeAction",
      "changeIotIdAction"
    ]),
    ...mapMutations("cesiumInfo", [
      "changeCesiumMarkerList",
      "changeNowMonomerInfo",
      "changeNowChooseMonomerInfoObj",
    ]),
    ...mapActions("cesiumInfo", [
      "chooseMonomerAction",
      "changeNowChooseMonomerInfoObjAction",
    ]),
    listenInfoTool() {
      let _this = this;

      window.infoToolClose = (id) => {
        window.cesiumInfoTool.remove(id);
        let videoElement = document.getElementById("videoElement");
        videoElement.stop();
      };

      window.infoToolErrorReport = (id) => {
        _this
          .$getApi(api.warningRead, {
            fileId: id,
            fileStatus: 2,
          })
          .then((res) => {
            window.cesiumInfoTool.remove(id);
            window.cesiumViewer.entityManager.removeByGroupId(id);
          });
      };

      window.infoToolSolve = (id) => {
        _this
          .$getApi(api.warningRead, {
            fileId: id,
            fileStatus: 1,
          })
          .then((res) => {
            window.cesiumInfoTool.remove(id);
            window.cesiumViewer.entityManager.removeByGroupId(id);
          });
      };
    },
    listenMouseCtrl() {
      let _this = this;
      document
        .getElementsByTagName("body")[0]
        .addEventListener("click", (e) => {
          let time = new Date();
          _this.mouseCtrl = 1;
          _this.mouseTime = time.getTime();
          clearInterval(_this.setIntervalWithCamera);
        });

      setInterval(() => {
        let time = new Date();
        if (
          _this.mouseCtrl == 1 &&
          time - _this.mouseTime > 30000 &&
          _this.slideShowType != "tool"
        ) {
          // 旋转间隔时间
          _this.mouseCtrl = 2;
        }
      }, 1000);
    },
    initMaxTime() {
      // 模型加载设置最长时间
      let _this = this;
      if (_this.initMaxTimer) {
        clearTimeout(_this.initMaxTimer);
        _this.initMaxTimer = null;
      }

      _this.initMaxTimer = setTimeout(() => {
        if (_this.doneTime < 2) {
          _this.doneTime = 2;
        }
      }, 60000);
    },
    init() {
      let _this = this;

      // cesium底座初始化
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkNmM2YzkyZS0wZjdjLTRlZWItOThiMC00ZTQwZjg3NDQxNjEiLCJpZCI6MzY5NjQsImlhdCI6MTYwNDM4NzA4OX0._GF1gmKRgo0neThQ3HSIRQ5dGpnE8kuM0OjaB3KPKFU";

      let viewerOption = {
        infoBox: false,
        geocoder: false, // 地理位置查询定位控件
        homeButton: false, // 默认相机位置控件
        timeline: false, // 时间滚动条控件
        navigationHelpButton: false, // 默认的相机控制提示控件
        fullscreenButton: false, // 全屏控件
        scene3DOnly: true, // 每个几何实例仅以3D渲染以节省GPU内存
        baseLayerPicker: false, // 底图切换控件
        animation: false, // 控制场景动画的播放速度控件
        // terrainProvider: Cesium.createWorldTerrain(),
      };

      window.cesiumViewer = new Cesium.Viewer("cesiumContainer", viewerOption);
      window.cesiumViewer._cesiumWidget._creditContainer.style.display = "none"; // 隐藏版权

      // cesium工具对象初始化
      new CesiumInit(window.cesiumViewer);

      // 加载地图
      // window.cesiumViewer.imageryLayers.addImageryProvider(
      //   new Cesium.WebMapTileServiceImageryProvider({
      //     url: "//{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&layer=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=e6e38c252d7f2463c8d11d6cf511d02f",
      //     layer: "tdtImgAnnoLayer",
      //     style: "default",
      //     format: "image/jpeg",
      //     tileMatrixSetID: "GoogleMapsCompatible",
      //     subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
      //   })
      // );

      // 监听tiles加载进度, 如果为0则进入街道界面
      let remove =
        window.cesiumViewer.scene.globe.tileLoadProgressEvent.addEventListener(
          (length) => {
            if (length == 0) {
              remove();
              _this.streetCameraFlyTo();
            }
          }
        );

      // cesium双击事件监听
      window.cesiumViewer.cesiumWidget.screenSpaceEventHandler.setInputAction(
        (event) => {
          var pick = window.cesiumViewer.scene.pick(event.position);
          if (defined(pick) && defined(pick.id) && defined(pick.id.info)) {
            console.log(pick);
            switch (pick.id.type) {
              case "area":
                _this.areaCameraFlyTo(pick.id);
                break;
              // case "monomer":
              //     _this.chooseMonomerAction(pick.id);
              //     break;
            }
          }
        },
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      // cesium点击事件监听-hepaz
      window.cesiumViewer.cesiumWidget.screenSpaceEventHandler.setInputAction(
        (event) => {
          var pick = window.cesiumViewer.scene.pick(event.position);
          console.log(pick);
          console.log(window.cesiumViewer.entities);
          if (defined(pick) && defined(pick.id) && defined(pick.id.info)) {
            switch (pick.id.type) {
              case "marker":
                if (pick.id.info.type == "CAMERA") {
                  pick.id.info.add = true;
                }

                window.cesiumInfoTool.add(pick.id);
                break;
              case "module": // 点击楼栋顶部的模型
                _this.changeSlideTypeAction("monomer_build");
                _this.changeNowMonomerInfo(pick.id.info);
                _this.changeNowChooseMonomerInfoObj({
                  id: "",
                });
                window.primitiveLayer.changePrimitiveColor(
                  pick.id.info.id,
                  [1, 0, 1, 0.5]
                );
                break;
              case "crowd":
                console.log("点击独巢老人图标");
                _this.changeBuildingIdAction(pick.id.build_id);
                _this.changeTagTypeAction(pick.id.tagtype);
                _this.changeSlideTypeAction("crowd");
                break;
              case "iot":
                console.log("报警设备图标");
                console.log(pick.id.id)
                console.log('pick.id.info',pick.id.info)
                _this.changeIotIdAction(pick.id.id);
                if (pick.id.info === 'lively'){
                  _this.changeSlideTypeAction("iot-lively");
                }
                if (pick.id.info === 'safe'){
                  _this.changeSlideTypeAction("iot-safe");
                }
                break;
            }
          } else {
            _this.changeSlideRoomShowAction(false);
          }

          // 获取点击坐标点
          CesiumValidate.getClickPointCartographic(event);
        },
        Cesium.ScreenSpaceEventType.LEFT_CLICK
      );

      // 获取模型当前加载进度
      let helper = new Cesium.EventHelper();
      helper.add(
        window.cesiumViewer.scene.globe.tileLoadProgressEvent,
        function (event) {
          if (event == 0) {
            ++_this.doneTime;
          }
        }
      );

      // infoTool监听事件
      this.listenInfoTool();
    },



    get3dTiles(orgId) {
      // 按需加载3dtiles模型
      let _this = this;
      let { areaHas3dtilesList, configInfo } = this;

      let chooseItem = null;
      let chooseItemIndex = -1;
      areaHas3dtilesList.forEach((item, index) => {
        if (item.orgId == orgId) {
          chooseItem = item;
          chooseItemIndex = index;
        }
      });

      if (chooseItemIndex > -1 && !chooseItem.has3dtiles) {
        let url = "http://oodcc.com:9090";
        switch (chooseItem.name) {
          case "幕府西路社区":
            url += "/cesium3d/nanjing/gulou/mufuxilu/tileset.json";
            break;
          case "金陵二村社区":
            url += "/cesium3d/nanjing/gulou/jl2c/data/Scene/Production_1.json";
            break;
        }

        if (url) {
          var palaceTileset = new Cesium.Cesium3DTileset({
            // 设置3dtile模型属性
            url: url,
            maximumScreenSpaceError: 1,
            maximumMemoryUsage: 2048,
            preferLeaves: true,
            modelMatrix: Cesium.Matrix4.fromTranslation(
              new Cesium.Cartesian3(0, 0, 0)
            ),
          });
          window.cesiumViewer.scene.primitives.add(palaceTileset); // 添加3dtile模型至地图中
          areaHas3dtilesList[chooseItemIndex].has3dtiles = true;
          this.areaHas3dtilesList = areaHas3dtilesList;
        }
      }
    },
    getCommonList() {
      let _this = this;
      let { commonData } = this;

      if (
        commonData.cameraWarningList &&
        commonData.cameraWarningList.length > 0
      ) {
        commonData.cameraWarningList.forEach((item, index) => {
          if (window.cesiumViewer.entityManager.getById(item.id)) {
          } else {
            let position = CesiumValidate.cartographicToCartesian({
              longitude: item.lon,
              latitude: item.lat,
              height: item.height,
            });
            window.cesiumViewer.entityManager.add(
              new Cesium.Entity({
                id: item.id,
                info: {
                  ...item,
                },
                type: "marker",
                position: position,
                billboard: {
                  image:
                    "https://nb-test.oss-cn-beijing.aliyuncs.com/image/c01a0a3f-c60d-4e0e-942f-aae76891bed5.png",
                  width: 30,
                  height: 30,
                  disableDepthTestDistance: 1000000000,
                  heightReference: Cesium.HeightReference.NONE,
                  verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                  classificationType: Cesium.ClassificationType.BOTH,
                },
              }),
              item.id
            );
          }
        });
      }

      if (commonData.fireList && commonData.fireList.length > 0) {
        commonData.fireList.forEach((item, index) => {
          if (window.cesiumViewer.entityManager.getById(item.id)) {
          } else {
            let position = CesiumValidate.cartographicToCartesian({
              // longitude: 118.769144653634,
              // latitude: 32.107566774164965,
              longitude: item.lon,
              latitude: item.lat,
              height: item.height + 5,
            });
            let position_cartesian = Cesium.Cartesian3.fromDegrees(
              item.lon,
              item.lat,
              item.height
            );
            let heading = Cesium.Math.toRadians(100);
            let pitch = 0;
            let roll = 0;
            let hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);

            let orientation = Cesium.Transforms.headingPitchRollQuaternion(
              position_cartesian,
              hpr
            );
            window.cesiumViewer.entityManager.add(
              new Cesium.Entity({
                id: item.id,
                info: {
                  ...item,
                },
                type: "marker",
                position: position,
                // billboard: {
                //     image: "https://nb-test.oss-cn-beijing.aliyuncs.com/image/992d6ccd-92eb-4ce4-aed4-2eb1e4d403aa.png",
                //     width: 30,
                //     height: 30,
                //     disableDepthTestDistance: 1000000000,
                //     heightReference:
                //         Cesium.HeightReference.NONE,
                //     verticalOrigin:
                //         Cesium.VerticalOrigin.BOTTOM,
                //     classificationType:
                //         Cesium.ClassificationType.BOTH,
                // },
                orientation,
                model: {
                  uri: "/module/baojing.glb",
                  scale: 5,
                },
              }),
              item.id
            );
          }
        });
      }

      if (
        commonData.smartWaterWarningList &&
        commonData.smartWaterWarningList.length > 0
      ) {
        commonData.smartWaterWarningList.forEach((item, index) => {
          if (window.cesiumViewer.entityManager.getById(item.id)) {
          } else {
            let position = CesiumValidate.cartographicToCartesian({
              longitude: item.lon,
              latitude: item.lat,
              height: item.height,
            });
            window.cesiumViewer.entityManager.add(
              new Cesium.Entity({
                id: item.id,
                info: {
                  ...item,
                },
                type: "marker",
                position: position,
                billboard: {
                  image:
                    "https://nb-test.oss-cn-beijing.aliyuncs.com/image/e1d44fdd-e97d-4efd-a1a7-1848d2e14875.png",
                  width: 30,
                  height: 30,
                  disableDepthTestDistance: 1000000000,
                  heightReference: Cesium.HeightReference.NONE,
                  verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                  classificationType: Cesium.ClassificationType.BOTH,
                },
              }),
              item.id
            );
          }
        });
      }

      if (commonData.partySign && commonData.partySign.length > 0) {
        commonData.partySign.forEach((item, index) => {
          window.primitiveLayer.changePrimitiveColor(item.areaId);
        });
      }
    },
    streetCameraFlyTo(
      duration = 0,
      orientation = {
        heading: 0.011385861624850335,
        pitch: -0.8020999269650986,
        roll: 0.000049583846767120576,
      }
    ) {
      let _this = this;
      this.nowEntityId = null;
      this.changeOrgId("12cf6ee284d511e8aabc00163e06cded");
      this.changeOrgIdAction("12cf6ee284d511e8aabc00163e06cded");
      this.changeOrgInfo({ name: "" });

      window.cesiumViewer.camera.flyTo({
        destination: new Cesium.Cartesian3(
          -2604337.907590034,
          4745140.146683104,
          3369855.6941764327
        ),
        duration,
        orientation,
      });

      this.cyclePoint = {
        longitude: 118.7596037952617,
        latitude: 32.11171961826268,
        height: 5700,
        rotate: 45,
      };

      setTimeout(() => {
        if (!_this.streetEntity) {
          _this.streetAreaInit(); // 加载区域
        }
        _this.areaInit();
      }, 1000);
    },
    areaCameraFlyTo(entityId, viewerInfo = {}) {
      let _this = this;
      this.get3dTiles(entityId.id);
      this.nowEntityId = entityId;

      if (viewerInfo.orientation) {
        // 判断视角是否有变更, 例如点击民生设施保障后, 视角会垂直
        let polyPositions = entityId.polygon.hierarchy.getValue(
          Cesium.JulianDate.now()
        ).positions;
        let centerPos = window.toolMakeArea.getPolygonCenter(polyPositions);
        let cartographicPos = CesiumValidate.cartesianToCartographic(centerPos);
        viewerInfo.cartographicCenterPos = {
          longitude: cartographicPos.longitude,
          latitude: cartographicPos.latitude,
        };
      }

      let area = window.cesiumViewer.entityManager.getById(entityId.id);
      if (defined(area)) {
        if (defined(_this.areaEneityTemp)) {
          area = _this.areaEneityTemp;
        }

        this.cyclePoint = {
          longitude: 118.76825207386261,
          latitude: 32.1075984192413,
          height: 320,
          rotate: 30,
        };

        let cameraInfo = {
          destination: Cesium.Cartesian3.fromDegrees(
            viewerInfo.cartographicCenterPos?.longitude ||
              area.info.viewPoint.longitude,
            viewerInfo.cartographicCenterPos?.latitude ||
              area.info.viewPoint.latitude,
            viewerInfo.height || area.info.viewPoint.height
          ),
          duration: 2,
          // orientation: viewerInfo.orientation ?,
          orientation: viewerInfo.orientation
            ? {
                heading: Cesium.Math.toRadians(0, 0),
                // 视角
                pitch: Cesium.Math.toRadians(viewerInfo.orientation.pitch),
                roll: 0.0,
              }
            : {
                direction: {
                  x: 0.6117391249580876,
                  y: -0.6049461023769074,
                  z: 0.5097209591673681,
                },
                up: {
                  x: -0.17260227846071627,
                  y: 0.526754129331783,
                  z: 0.8323091617314354,
                },
              },
        };

        window.cesiumViewer.camera.flyTo(cameraInfo);

        setTimeout(() => {
          if (!_this.moduleEntity) {
            _this.moduleUnit(); // 加载模型
          }
        }, 3000);

        _this.changeOrgId(area.info.orgId);
        _this.changeOrgIdAction(area.info.orgId);
        _this.changeOrgInfo(area.info);
        // 视角飞入社区则改变slideType为point
        _this.changeSlideTypeAction("point");
        console.log(_this.slideTypeIndex);
        if (_this.slideTypeIndex == -1) {
          _this.changeSlideTypeIndex(0);
          _this.areaListTemp.forEach((item) => {
            if (item.orgId != entityId.id) {
              window.cesiumViewer.entityManager.removeByGroupId(item.orgId);
            } else {
              let nowArea = window.cesiumViewer.entityManager.getById(
                item.orgId
              );
              nowArea.polygon.material =
                Cesium.Color.fromCssColorString("white").withAlpha(0);
              nowArea.polyline.material =
                Cesium.Color.fromCssColorString("white").withAlpha(0);
            }
          });

          setTimeout(() => {
            if (_this.monomerList.length == 0) {
              _this
                .$getApi(api.getMonomerList, {
                  orgId: area.info.orgId,
                  buildUnit: "buildUnit",
                })
                .then((res) => {
                  if (1 == res.code) {
                    let list = res.data.monomerList.map((item) => {
                      if (item.coordinates.length > 0) {
                        let arr = [];
                        item.coordinates = JSON.parse(item.coordinates);
                        let coordinatesArr = item.coordinates;
                        coordinatesArr.forEach((position, index) => {
                          if (index % 2 == 0) {
                            arr.push([position, coordinatesArr[index + 1]]);
                          }
                        });

                        let newCoordinates = arr.map((position, index) => {
                          return {
                            longitude: position[0],
                            latitude: position[1],
                          };
                        });

                        item.newCoordinates = newCoordinates;

                        let cartesian = newCoordinates.map((position) => {
                          return CesiumValidate.cartographicToCartesian({
                            longitude: position.longitude,
                            latitude: position.latitude,
                            height: 5,
                          });
                        });

                        item.cartesian = cartesian;

                        let centerPoint =
                          Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(
                            Cesium.BoundingSphere.fromPoints(cartesian).center
                          );
                        item.centerPoint =
                          CesiumValidate.cartesianToCartographic(centerPoint);

                        let obj = {
                          id: item.id,
                          info: { type: item.type },
                          type: "monomer",
                        };
                        item.id = obj;
                      }
                      return item;
                    });

                    _this.monomerList = list;
                    _this.monomerInit(list);
                  }
                });
            }
          }, 100);
        } else {
          if (entityId.id) {
            let nowArea = window.cesiumViewer.entityManager.getById(
              entityId.id
            );
            nowArea.polygon.material =
              Cesium.Color.fromCssColorString("white").withAlpha(0);
            nowArea.polyline.material =
              Cesium.Color.fromCssColorString("white").withAlpha(0);
          }
        }
      }
    },
    //宝塔桥街道最外圈褐色
    streetAreaInit() {
      let _this = this;

      // return new Promise((resolve, reject) => {
      axios.get("/data/streetAreaData.json").then((streetArea) => {
        if (200 == streetArea.status) {
          let streetCartesian = streetArea.data.coordinates.map((position) => {
            return CesiumValidate.cartographicToCartesian({
              longitude: position.longitude,
              latitude: position.latitude,
              height: 5,
            });
          });

          window.cesiumViewer.entityManager.add(
            {
              id: streetArea.data.orgId,
              info: {
                type: streetArea.data.type,
                hoverColor: streetArea.data.colorSelected,
                defaultColor: streetArea.data.colorDefault,
                orgId: streetArea.data.orgId,
              },
              polyline: {
                positions: new Cesium.CallbackProperty(() => {
                  return streetCartesian;
                }, false),
                material: Cesium.Color.fromCssColorString(
                  streetArea.data.colorDefault
                ).withAlpha(0.8),
                clampToGround: true,
                width: 4,
              },
            },
            streetArea.data.orgId
          );

          _this.streetEntity = true;

          // resolve(1)
        }
      });
      // });
    },

    areaInit() {
      let _this = this;
      let { nowEntityId } = this;
      return new Promise((resolve, reject) => {
        this.$getApi(api.getAreaList, {}).then((res) => {
          if (1 == res.code) {
            res.data.areaList.forEach((item) => {
              item.has3dtiles = false;
              if (item.coordinates != "") {
                item.coordinatesArr = JSON.parse(item.coordinates);

                if (item.coordinatesArr.length > 0) {
                  let cartesian = item.coordinatesArr.map((position) => {
                    return CesiumValidate.cartographicToCartesian({
                      longitude: position.longitude,
                      latitude: position.latitude,
                      height: 5,
                    });
                  });

                  let hasEntity = window.cesiumViewer.entityManager.getById(
                    item.orgId
                  );

                  if (hasEntity) {
                    window.cesiumViewer.entityManager.removeById(item.orgId);
                  }
                  // if (!hasEntity) {
                  window.cesiumViewer.entityManager.add(
                    {
                      id: item.orgId,
                      info: {
                        type: item.type,
                        hoverColor: item.colorSelected,
                        defaultColor: item.colorDefault,
                        orgId: item.orgId,
                        coordinates: item.coordinatesArr,
                        name: item.name,
                        viewPoint: {
                          longitude: item.viewLon,
                          latitude: item.viewLat,
                          height: item.viewHeight,
                        },
                      },
                      polygon: {
                        hierarchy: new Cesium.CallbackProperty(() => {
                          return {
                            positions: cartesian,
                          };
                        }, false),
                        material: nowEntityId
                          ? Cesium.Color.fromCssColorString(
                              item.colorDefault
                            ).withAlpha(0)
                          : Cesium.Color.fromCssColorString(
                              item.colorDefault
                            ).withAlpha(0.2),
                        // outline: true,
                        // outlineColor: nowEntityId
                        //     ? Cesium.Color.fromCssColorString(
                        //           item.colorDefault
                        //       ).withAlpha(0)
                        //     : Cesium.Color.fromCssColorString(
                        //           item.colorDefault
                        //       ).withAlpha(1),
                        // outlineWidth: 4,
                        // height: 5,
                        // extrudedHeight: 5,
                        // perPositionHeight: true,
                        closeTop: false,
                        closeBottom: false,
                      },
                      type: "area",
                      polyline: {
                        positions: new Cesium.CallbackProperty(() => {
                          return cartesian;
                        }, false),
                        material: nowEntityId
                          ? Cesium.Color.fromCssColorString(
                              item.colorDefault
                            ).withAlpha(0)
                          : Cesium.Color.fromCssColorString(
                              item.colorDefault
                            ).withAlpha(0.6),
                        clampToGround: true,
                        width: 2,
                      },
                    },
                    item.orgId
                  );
                  // }
                }
              }
            });
            _this.areaListTemp = res.data.areaList;
            if (_this.areaHas3dtilesList.length == 0) {
              _this.areaHas3dtilesList = res.data.areaList.map((item) => {
                return {
                  has3dtiles: false,
                  name: item.name,
                  orgId: item.orgId,
                };
              });
            }
          }
          resolve(res);
        });
      });
    },
    areaInit2() {
      let that = this;
      console.log("x0");

      if (that.areaOrgList.length === 0) {
        console.log("绘制网格");
        axios.get("/data/areaGrid.json").then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            console.log(res.data.data.areaList);
            res.data.data.areaList.forEach((item) => {
              if (item.coordinates != "") {
                item.coordinatesArr = JSON.parse(item.coordinates);

                let polyline_point_arr = [];
                for (let i = 0; i < item.coordinatesArr.length; i++) {
                  polyline_point_arr.push(item.coordinatesArr[i].longitude);
                  polyline_point_arr.push(item.coordinatesArr[i].latitude);
                  //polyline_point_arr.push(20);
                }
                polyline_point_arr.push(item.coordinatesArr[0].longitude);
                polyline_point_arr.push(item.coordinatesArr[0].latitude);
                //polyline_point_arr.push(20);
                window.cesiumViewer.entities.add({
                  name: "newPolygon",
                  id: item.orgId,
                  polyline: {
                    positions:
                      Cesium.Cartesian3.fromDegreesArray(polyline_point_arr),
                    clampToGround: true, //贴地画线
                    width: 5.0,
                    material: Cesium.Color.fromCssColorString(
                      item.colorDefault
                    ),
                    depthFailMaterial: Cesium.Color.fromCssColorString(
                      item.colorDefault
                    ),
                  },
                });
              }

              that.areaOrgList.push(item.orgId);
            });
          }
        });
      } else {
        console.log("网格线已经存在");
      }
    },
    hiddenArea() {
      console.log("删除网格线");
      let that = this;
      //删除实体
      if (that.areaOrgList.length > 0) {
        for (let i = 0; i < that.areaOrgList.length; i++) {
          console.log(that.areaOrgList[i]);
          window.cesiumViewer.entities.removeById(that.areaOrgList[i]);
        }
        that.areaOrgList = [];
      }
      //改变实体颜色
      // console.log(window.cesiumViewer.entities);
      // if(that.areaOrgList.length > 0){
      //     for(let i=0;i<that.areaOrgList.length;i++){
      //         window.cesiumViewer.entities.getById(that.areaOrgList[i])._polyline._material._color._value = new Cesium.Color(0, 0, 0, 0);
      //         //window.cesiumViewer.entities.removeById(that.areaOrgList[i]);
      //     }
      //     that.areaOrgList = [];
      // }
      //console.log(window.cesiumViewer.entities.getById(that.areaOrgList[0]));
    },
    monomerInit(list) {
      let _this = this;

      window.primitiveLayer.addPolygons(list);

      setTimeout(() => {
        _this.buildModuleUnit(list);
      }, 2000);
    },
    //加载楼栋顶部文字以及图标
    buildModuleUnit(list) {
      let _this = this;
      let buildingModuleUrl = "/module/building.glb";

      list.forEach((item, index) => {
        if ("build" === item.type) {
          let position = Cesium.Cartesian3.fromDegrees(
            item.centerPoint.longitude,
            item.centerPoint.latitude,
            10 + item.height
          );
          window.cesiumViewer.entities.add({
            id: item.id.id,
            info: {
              ...item.id,
            },
            type: "module",
            position: position,
            orientation: Cesium.Transforms.headingPitchRollQuaternion(
              position,
              new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(100), 0, 0)
            ),
            model: {
              uri: buildingModuleUrl,
              scale: 12,
            },
            label: {
              text:
                item.name +
                "\n" +
                (item.userName || "") +
                "\n" +
                (item.phone || ""),
              font: "15px Microsoft YaHei",
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              fillColor:
                Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
              outlineWidth: 3,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.TOP,
              // pixelOffset: new Cesium.Cartesian2(-40, -65), // 偏移量
              eyeOffset: Cesium.Cartesian3.fromElements(0, 7, -50),
            },
            attrInfo: "",
            description: "",
          });
        }
      });
    },

    //加载垃圾桶
    moduleUnit() {
      let _this = this;
      let lajitingModuleUrl = "/module/lajiting.glb";

      let positionDefault = {
        longitude: 118.76817376725505,
        latitude: 32.10801464604632,
        height: 0,
      };

      this.$getApi(api.getDotList, {
        type: "GARBAGE_STATION",
        page: 1,
        size: 1000,
      }).then((res) => {
        if (1 == res.code) {
          let rotateArr = [230, 210, 135, -60];
          res.data.dotList.forEach((item, index) => {
            let position = Cesium.Cartesian3.fromDegrees(
              item.lon,
              item.lat,
              item.height
            );
            window.cesiumViewer.entities.add({
              info: {
                id: item.id,
                type: "GARBAGE_STATION",
              },
              type: "marker",
              position: position,
              orientation: _this.setModelDirection(
                item.lon,
                item.lat,
                rotateArr[index] || 0
              ),
              model: {
                uri: lajitingModuleUrl,
                scale: 1.5,
              },
              id: item.id,
            });
          });

          _this.moduleEntity = true;
        }
      });

      // let positionList = [
      //     {
      //         longitude: 118.77016053721583,
      //         latitude: 32.10972104127638,
      //         height: 22,
      //         rotate: 230,
      //     },
      //     {
      //         longitude: 118.76709001487984,
      //         latitude: 32.10614423576045,
      //         height: 22,
      //         rotate: 210,
      //     },
      //     {
      //         longitude: 118.76802266505715,
      //         latitude: 32.10659726778601,
      //         height: 22,
      //         rotate: 135,
      //     },
      //     {
      //         longitude: 118.76649306274699,
      //         latitude: 32.106841156184494,
      //         height: 22,
      //         rotate: -60,
      //     },
      // ];

      // let position = Cesium.Cartesian3.fromDegrees(
      //     positionTemp.longitude,
      //     positionTemp.latitude,
      //     positionTemp.height
      // );
    },

    setModelDirection(longitude, latitude, direction) {
      var center = Cesium.Cartesian3.fromDegrees(longitude, latitude, 0);
      var heading = Cesium.Math.toRadians(direction);
      var pitch = 0;
      var roll = 0;
      var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
      var orientation = Cesium.Transforms.headingPitchRollQuaternion(
        center,
        hpr
      );

      return orientation;
    },

    removeAll(
      options = {
        entity: true,
        scene: true,
      }
    ) {
      if (options.entity) {
        window.cesiumViewer.entities.removeAll();
      }

      if (options.scene) {
        window.cesiumViewer.scene.postProcessStages.removeAll();
      }
    },
    addMarker(list) {
      let _this = this;
      this.removeAll();
      let markerStyle = {
        width: 50,
        height: 50,
      };

      list.forEach((item) => {
        //先画线 hepaz
        let linePositions = [];
        linePositions.push(
          new Cesium.Cartesian3.fromDegrees(
            item.position.lon,
            item.position.lat,
            item.position.height
          )
        );
        linePositions.push(
          new Cesium.Cartesian3.fromDegrees(
            item.position.lon,
            item.position.lat,
            item.position.height + 20
          )
        );
        window.cesiumViewer.entities.add({
          polyline: {
            positions: linePositions,
            width: 2,
            material: Cesium.Color.fromCssColorString("#0e26f1"), //纯色
          },
        });

        window.cesiumViewer.entities.add({
          id: item.id,
          info: item.info,
          type: "marker",
          position: Cesium.Cartesian3.fromDegrees(
            item.position.lon,
            item.position.lat,
            item.position.height + 20
          ),
          label: {
            text: item.text,
            font: "16px PingFangSC-Regular",
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
            outlineWidth: 3,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(15, -2), //偏移量
            disableDepthTestDistance: 1000000000,
            classificationType: Cesium.ClassificationType.BOTH,
          },
          billboard: {
            image: item.icon.src,
            width: item.icon.width || markerStyle.width,
            height: item.icon.height || markerStyle.height,
            color: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
            disableDepthTestDistance: 1000000000,
            heightReference: Cesium.HeightReference.NONE,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            classificationType: Cesium.ClassificationType.BOTH,
          },
        });
      });
    },
    playLive(data) {
      let _this = this;

      // _this.$get(api.videos).then((res) => {
      //     if (1 == res.errorCode) {
      let video = {
        // url: res.data.UAVFLVURL,
        // url: "rtmp://58.200.131.2:1935/livetv/cctv5",
        // url: "http://play3.skysys.cn/xl_uav/ch29.flv?auth_key=1622085946-0-0-c2365b65696fa892c0d0e80c9cbbc8bf",
        // url: "http://play2.skysys.cn/xl_uav/ch17.flv?auth_key=1622021213-0-0-6653f3c6d66a799d1b963faeeec9a117",
        url: data.content,
        type: "customFlv",
        customType: {
          customFlv: function (video, player) {
            const flvPlayer = flvjs.createPlayer({
              type: "flv",
              url: video.src,
            });
            flvPlayer.attachMediaElement(video);
            flvPlayer.load();
          },
        },
      };

      _this.playVideo({
        video: video,
      });
      //     }
      // });
    },
    playVideo(data) {
      let { video } = data;
      let _this = this;

      let dp = new DPlayer({
        container: document.getElementById("dplayer"),
        video,
      });

      if (this.playUrl === video.url) {
        if (this.isLiveShow) {
          // this.handleLiveVisibleChange(false);
          this.isLiveShow = false;
        } else {
          dp.play();
          this.isLiveShow = true;
        }
      } else {
        this.playUrl = video.url;
        this.isLiveShow = true;

        if (dp) {
          //@ts-ignore
          dp.switchVideo(video);
        } else {
          dp = new DPlayer({
            live: true,
            mutex: false,
            // container: _this.$refs.dplayer as HTMLElement,
            container: document.getElementById("dplayer"),
            video,
          });
        }
      }
    },
  },
};
