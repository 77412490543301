
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/index.js";
import util from "@/assets/js/util";

import ToolTop from "../components/tool/top";
import ToolIconRight from "../components/tool/right.vue";
import ToolRoomLeft from "../components/slideRoom/toolRoomLeft";
import ToolRoomRight from "../components/slideRoom/toolRoomRight";
import Windialog from "../components/publicDillog/winDialog";

import CesiumRoom from "../components/cesiumRoom";
import Loading from "@/views/loading/index.vue";
import BottomRoom from "@/views/slideBottom/bottom";
import StreetIndex from "@/views/street/index.vue";
import GFZZIndex from "@/views/gfzz/index.vue";
import MSBZIndex from "@/views/msbz/index.vue";
import HLDJIndex from "@/views/hldj/index.vue";
import PAJKIndex from "@/views/pajk/index.vue";
import CSGLIndex from "@/views/csgl/index.vue";
import MonomerSlide from "@/views/monomerInfo/index.vue";
import crowdSlide from "@/views/monomerInfo/crowd.vue";
import iotSlide from "@/views/monomerInfo/iot.vue";
import config from "@/store/module/config";
import Login from "@/views/Login.vue";
import * as Cesium from "cesium";
const canvas = document.createElement("canvas");

canvas.width = 256;
canvas.height = 256;
const context = canvas.getContext("2d");

export default {
  name: "Home",
  components: {
    Windialog,
    BottomRoom,
    ToolTop,
    ToolIconRight,
    ToolRoomLeft,
    CesiumRoom,
    ToolRoomRight,
    Loading,
    StreetIndex,
    GFZZIndex,
    MSBZIndex,
    HLDJIndex,
    PAJKIndex,
    CSGLIndex,
    MonomerSlide,
    crowdSlide,
    iotSlide
  },
  computed: {
    ...mapGetters("publicInfo", ["nowOrgId"]),
    ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    ...mapGetters("msbz", ["msbzData"]),
    ...mapGetters("pajk", ["pajkData"]),
    ...mapGetters("hldj", ["hldjData"]),
    ...mapState(["refreshNumber", "configInfo"]),
    ...mapGetters("config", ["configInfo"]),
  },
  watch: {
    gfzzGetApi(newData, oldData) {
      if (newData) {
        this.getApiTimeString = new Date().getTime();
        this.startGetApi = true;
      }
    },
    startGetApi(newState, oldState) {
      let _this = this;
      let { startGetApiSetTime } = this;
      if (newState && newState != oldState) {
        setTimeout(() => {
          // _this.getApi();
        }, startGetApiSetTime);
      }
    },
    nowOrgId(newId, oldId) {
      let _this = this;
      if (this.ws) {
        this.websocketClose().then((code) => {
          _this.createWebSocket(newId);
        });
      } else {
        _this.createWebSocket(newId);
      }
    },
  },
  data() {
    return {
      startGetApi: false,
      getApiSetTime: 5000,
      getApiTimeString: new Date().getTime(),
      ws: null,
      wsUrl: "",
      socketHeart: {
        timeout: 6000,
        timeoutObj: null,
        serverTimeoutObj: null,
        code: "66666",
      },
      lockReconnect: false,
      reconnectTimer: null,
      time:0
    };
  },
  mounted() {
    let _this = this;

    window.onresize = function () {
      _this.changeEChartsLoad(false);

      if (_this.windowResizeTimer) {
        clearTimeout(_this.windowResizeTimer);
        _this.windowResizeTimer = null;
      }

      _this.windowResizeTimer = setTimeout(() => {
        _this.changeEChartsLoad(true);
      }, 500);
    };
    // this.changeSlideRoomShowAction('street')
  },
  methods: {
    ...mapMutations("publicInfo", ["changeEChartsLoad"]),
    ...mapMutations("gfzz", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("msbz", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("pajk", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("csgl", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("hldj", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapActions("publicInfo", ["changeSlideRoomShowAction"]),
    ...mapActions(["changeCommonDataAction", "changeRefreshNumberAction"]),
    strikeRefsWithAreaCameraFlyTo() {
      let nowEntityId = this.$refs.cesiumRoom.$data.nowEntityId;
      let viewerInfo = {
        height: 1500,
        orientation: {
          heading: 90,
          pitch: -90,
          roll: 0,
        },
      };
      this.$refs.cesiumRoom.areaCameraFlyTo(nowEntityId, viewerInfo);
    },
    createWebSocket(orgId) {
      console.log('创建websocket连接', orgId)
      let _this = this;
      let { wsUrl} = this;
      try {
        console.log('try')
        _this.wsUrl = config.state.configInfo.websocketApi + "/websocket/" + orgId + "/" + util.createNonceStr();
        _this.ws = new WebSocket(_this.wsUrl);
        _this.websocketInit();
      } catch (e) {
        console.log('catch')
        _this.reconnect(wsUrl);
      }
    },
    websocketInit() {
      let _this = this;
      let { refreshNumber, socketHeart, gfzzData, gfzzGetApi } = this;
      console.log(11);
      if ("WebSocket" in window) {
        // var ws = new WebSocket("ws://localhost:8880/websocket/a849dbae84d611e8aabc00163e06cded");

        this.ws.onopen = function () {
          _this.heartStart();
        };

        this.ws.onmessage = function (e) {
          var received_msg = JSON.parse(e.data);
          _this.heartStart();

          if (
            received_msg.content.code &&
            received_msg.content.code === socketHeart.code
          ) {
            console.log("心跳检测返回");
          } else {
            _this.changeRefreshNumberAction(++refreshNumber);

            if (received_msg.content.gfzz) {
              if (
                _this.gfzzGetApi &&
                received_msg.content.gfzz.point_exchange
              ) {
                received_msg.content.gfzz.point_exchange =
                  received_msg.content.gfzz.point_exchange.concat(
                    _this.gfzzData.point_exchange
                  );
              }

              _this.changeGFZZData(received_msg.content.gfzz);
            }

            if (received_msg.content.msbz) {
              _this.changeMSBZData(received_msg.content.msbz);
            }

            if (received_msg.content.pajk) {
              _this.changePAJKData(received_msg.content.pajk);
            }

            if (received_msg.content.csgl) {
              _this.changeCSGLData(received_msg.content.csgl);
            }

            if (received_msg.content.hldj) {
              _this.changeHLDJData(received_msg.content.hldj);
            }

            if (received_msg.content.common) {
              _this.changeCommonDataAction(received_msg.content.common);
            }
            console.log('received_msg',received_msg)
            if(received_msg.content.upType === 'SOS'){
              console.log('sos信息')

              ////////////////////////////
              if(received_msg.content.upStatus === '0'){
                _this.specialEffects()
                _this.getEllipseModel1(received_msg.content)
                _this.getEllipseModel2(received_msg.content)
              }else{
                window.cesiumViewer.entities.removeById('Ellipse1-' + received_msg.content.id)

                window.cesiumViewer.entities.removeById('Ellipse2-' + received_msg.content.id)
              }

              /////////////////////////
            }


          }
        };

        this.ws.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
        };
      }
    },
    websocketClose() {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this.ws.close();
        _this.ws = null;
        _this.wsUrl = "";
        _this.socketHeart = {
          timeout: 6000,
          timeoutObj: null,
          serverTimeoutObj: null,
        };
        _this.lockReconnect = false;
        _this.reconnectTimer = null;
        resolve(1);
      });
    },
    heartStart() {
      let { timeout, code } = this.socketHeart;
      var _this = this;
      this.timeoutObj && clearTimeout(_this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
      this.timeoutObj = setTimeout(function () {
        _this.ws.send(
          JSON.stringify({
            id: "",
            msgType: "",
            fromUser: "",
            toUser: "",
            content: {
              code: code,
            },
            dealUrl: "",
          })
        );
        _this.serverTimeoutObj = setTimeout(function () {
          console.log("后台挂掉，没有心跳了....");
          _this.websocketClose();
        }, timeout);
      }, timeout);
    },
    reconnect(url) {
      let _this = this;
      let { lockReconnect, reconnectTimer, nowOrgId } = this;
      if (lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      reconnectTimer && clearTimeout(reconnectTimer);
      this.reconnectTimer = setTimeout(function () {
        _this.createWebSocket(nowOrgId);
        _this.lockReconnect = false;
      }, 20000);
    },
    getApi() {
      let _this = this;
      let { getApiSetTime, refreshNumber } = this;

      setInterval(() => {
        _this
          .$getApi(api.refresh, {
            queryTime: _this.getApiTimeString,
          })
          .then((res) => {
            if (1 == res.code) {
              _this.getApiTimeString = res.data.queryTime;

              _this.changeGFZZData(res.data.gfzz);
              _this.changeMSBZData(res.data.msbz);
              _this.changePAJKData(res.data.pajk);
              _this.changeCSGLData(res.data.csgl);
              _this.changeHLDJData(res.data.hldj);
              _this.changeCommonDataAction(res.data.common);

              _this.changeRefreshNumberAction(++refreshNumber);
            }
          });
      }, getApiSetTime);
    },
    // 特效方法实例
    specialEffects () {
      function CircleWaveMaterialProperty (color, duration, count, gradient) {
        this._definitionChanged = new Cesium.Event()
        this._color = undefined
        this._colorSubscription = undefined
        this.color = color
        this.duration = Cesium.defaultValue(duration, 1e3)
        this.count = Cesium.defaultValue(count, 2)
        if (this.count <= 0) this.count = 1
        this.gradient = Cesium.defaultValue(gradient, 0.1)
        if (this.gradient < 0) this.gradient = 0
        else if (this.gradient > 1) this.gradient = 1
        this._time = performance.now()
      }
      Object.defineProperties(CircleWaveMaterialProperty.prototype, {
        isConstant: {
          get: function () {
            return false
          }
        },
        definitionChanged: {
          get: function () {
            return this._definitionChanged
          }
        },
        color: Cesium.createPropertyDescriptor('color')
      })
      CircleWaveMaterialProperty.prototype.getType = function (time) {
        return Cesium.Material.CircleWaveMaterialType
      }
      CircleWaveMaterialProperty.prototype.getValue = function (time, result) {
        if (!Cesium.defined(result)) {
          result = {}
        }
        result.color = Cesium.Property.getValueOrClonedDefault(this._color, time, Cesium.Color.WHITE, result.color)
        result.time = (((new Date()).getTime() - this._time) % this.duration) / this.duration
        result.count = this.count
        result.gradient = 1 + 10 * (1 - this.gradient)
        return result
      }
      CircleWaveMaterialProperty.prototype.equals = function (other) {
        return this === other ||
            (other instanceof CircleWaveMaterialProperty &&
                Cesium.Property.equals(this._color, other._color))
      }
      Cesium.CircleWaveMaterialProperty = CircleWaveMaterialProperty
      Cesium.Material.CircleWaveMaterialType = 'CircleWaveMaterial'
      Cesium.Material.CircleWaveSource = `czm_material czm_getMaterial(czm_materialInput materialInput)\n
    {\n
        czm_material material = czm_getDefaultMaterial(materialInput);\n
        material.diffuse = 1.5 * color.rgb;\n
        vec2 st = materialInput.st;\n
        vec3 str = materialInput.str;\n
        float dis = distance(st, vec2(0.5, 0.5));\n
        float per = fract(time);\n
        if (abs(str.z) > 0.001) {\n
            discard;\n
        }\n
        if (dis > 0.5) { \n
            discard; \n
        } else { \n
            float perDis = 0.5 / count;\n
            float disNum; \n
            float bl = .0; \n
            for (int i = 0; i <= 999; i++) { \n
                if (float(i) <= count) { \n
                  disNum = perDis *
    float(i) - dis + per / count; \n
                    if (disNum > 0.0) { \n
                        if (disNum < perDis) { \n
                            bl = 1.0 - disNum / perDis;\n
                        }\n
                      else if
    (disNum - perDis < perDis) { \n
                                bl = 1.0 - abs(1.0 - disNum / perDis); \n
                        } \n
                        material.alpha = pow(bl, gradient); \n
                    } \n
                } \n
            } \n
        } \n
    return material; \n
    } \n`
      Cesium.Material._materialCache.addMaterial(Cesium.Material.CircleWaveMaterialType, {
        fabric: {
          type: Cesium.Material.CircleWaveMaterialType,
          uniforms: {
            color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),
            time: 1,
            count: 1,
            gradient: 0.1
          },
          source: Cesium.Material.CircleWaveSource
        },
        translucent: function (material) {
          return !0
        }
      })
    },
    // 圆圈模型蓝色单线圈
    getEllipseModel1 (data) {

      data.longitude = parseFloat(data.longitude)
      data.latitude = parseFloat(data.latitude)
      if(data.height === ''){
        data.height = 10
      }

      // let linePositions = [];
      // linePositions.push(new Cesium.Cartesian3.fromDegrees(data.longitude, data.latitude, data.height));
      // linePositions.push(new Cesium.Cartesian3.fromDegrees(data.longitude, data.latitude, 60));
      window.cesiumViewer.entities.add({
        id: 'Ellipse1-'+data.id,
        name: 'Ellipse1',
        position: new Cesium.Cartesian3.fromDegrees(data.longitude, data.latitude, 20),
        // polyline: {
        //   positions: linePositions,
        //   width: 2,
        //   material: Cesium.Color.fromCssColorString("#ff0000"), //纯色
        // },
        label: {
          text: data.upLocation,
          font: "16px PingFangSC-Regular",
          backgroundColor: Cesium.Color.fromCssColorString("#ffc400").withAlpha(1),
          showBackground: true,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          fillColor: Cesium.Color.fromCssColorString("#ffffff"),
          outlineWidth: 0,
          horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(-50, -50), //偏移量
          disableDepthTestDistance: 1000000000,
          classificationType: Cesium.ClassificationType.BOTH,
        },
        billboard: {
          image: '/images/crowd/crowd_' + data.upType + '.png',
          width: 45,
          height: 45,
          color: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
          disableDepthTestDistance: 1000000000,
          heightReference: Cesium.HeightReference.NONE,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          classificationType: Cesium.ClassificationType.BOTH,
        },
        ellipse: {
          height: 30, // 线圈的高度
          semiMinorAxis: 100, // 线圈的横向长度
          semiMajorAxis: 100, // 线圈的竖向长度
          material: new Cesium.CircleWaveMaterialProperty(Cesium.Color.fromCssColorString('rgba(1, 213, 222, 0.6)'), 2e3, 1, 0), // 第一个参数是线圈的颜色，第二个参数是线圈的个数
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(500.0, 15000.0) // 在限定高度范围内才显示线圈
        }
      })
    },
    // 圆圈模型红色多线圈
    getEllipseModel2 (data) {
      data.longitude = parseFloat(data.longitude)
      data.latitude = parseFloat(data.latitude)
      if(data.height === ''){
        data.height = 20
      }
      window.cesiumViewer.entities.add({
        id: 'Ellipse2-' + data.id,
        name: 'Ellipse2',
        position: new Cesium.Cartesian3.fromDegrees(data.longitude, data.latitude, 20),
        ellipse: {
          height: 30,
          semiMinorAxis: 50,
          semiMajorAxis: 50,
          material: new Cesium.CircleWaveMaterialProperty(Cesium.Color.fromCssColorString('#E54030'), 2e3, 3, 0)
        }
      })
      //相机飞行到点位
      window.cesiumViewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(data.longitude, data.latitude, 1000),
        orientation: {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: 0
        },
        duration: 1
      })
    },
  },
};
