import { render, staticRenderFns } from "./safeReport.vue?vue&type=template&id=6a9d04dc&scoped=true"
import script from "./safeReport.vue?vue&type=script&lang=js"
export * from "./safeReport.vue?vue&type=script&lang=js"
import style0 from "./safeReport.vue?vue&type=style&index=0&id=6a9d04dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a9d04dc",
  null
  
)

export default component.exports