
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/index";
import TitleText from "@/components/titleText.vue";
import store from "@/store";
import axios from "axios";
import * as Cesium from 'cesium';

export default {
    name: "componentDemo",
    components: {
        TitleText,
    },
    computed: {
        ...mapState(["winShow", "winShowType"]),
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        pajkGetApi(newObj, oldObj) {
            if (newObj) {
                this.getContentList();
            }
        },
    },
    data() {
        return {
            safelyList: [
                {
                    avatar: "icontuandui",
                    unit: "人",
                    num: 0,
                    title: "总人口",
                    type: "person",
                },
                {
                    avatar: "iconzhufang-mianxing",
                    unit: "户",
                    num: 0,
                    title: "住房数",
                    type: "house",
                },
                {
                    avatar: "iconqiye",
                    unit: "个",
                    num: 0,
                    title: "辖区企业",
                    type: "company",
                },
                {
                    avatar: "icondianpu",
                    unit: "个",
                    num: 0,
                    title: "九小场所",
                    type: "place",
                },
                {
                    avatar: "iconshexiangtou-01",
                    unit: "件",
                    num: 0,
                    title: "监控总数",
                    type: "CAMERA",
                },
                {
                  avatar: "iconhuoqing-",
                  unit: "个",
                  num: 0,
                  title: "火情告警",
                  type: "fire_count",
                },
            ],
            safelyIndex: -1,
            iotShow: false
        };
    },

    mounted() {
        if (this.pajkGetApi) {
            this.getContentList();
        }
        // this.safelyListFn();
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showFn(item, index) {

            let _this = this;

          console.log("item",item);
          console.log("index",index);

          if(index === 5){
            if(_this.iotShow){
              window.cesiumViewer.entities.removeAll();
              _this.iotShow = false;
              return;
            }else{
              _this.get_fire_device_list();
              return;
            }
          }

            let { winShow, winShowType, safelyIndex } = this;
            
            this.changeCesiumMarkerList([]);

            switch (item.type) {
                case "company":
                    winShow = true;
                    winShowType = "company_count";
                    break;
                case "place":
                    winShow = true;
                    winShowType = "nine_small_count";
                    break;
                case "fire_count":
                    winShow = true;
                    winShowType = "fire_count";
                    break;
                default:
                    if (index !== safelyIndex) {
                        this.showCesiumMarker(item);
                    }
                    break;
            }

            this.safelyIndex = index == safelyIndex ? -1 : index;

            if (winShow) {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction(winShowType);
            }
        },
        get_fire_device_list(){
          let _this = this;
          let params = {
            iotType: 8,
            org_id:  store.state.publicInfo.nowOrgId
          }
          axios.get('/api_btq/getIotDeviceList', {params: params}).then(res=>{
            console.log(res.data.data.iotList)
            if(res.data && res.data.data && res.data.data.iotList){
              _this.add_marker(res.data.data.iotList)
              _this.iotShow = true;
            }
          })
        },
      //添加标注
      add_marker(data){
        //隐藏其他所有的模型
        window.cesiumViewer.entities.removeAll();
        if (data.length > 0) {
          data.forEach((item) => {
            //console.log(item);
            item.longitude = parseFloat(item.longitude)
            item.latitude = parseFloat(item.latitude)
            //先画线
            if(!item.height){
              item.height = 20
            }
            let linePositions = [];
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, item.height));
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude,  item.height + 50));

            window.cesiumViewer.entities.add({
              id: item.iotId,
              info: 'safe',
              type: "iot",
              position: Cesium.Cartesian3.fromDegrees(
                  item.longitude,
                  item.latitude,
                  item.height+50
              ),
              polyline: {
                positions: linePositions,
                width: 2,
                material: Cesium.Color.fromCssColorString("#ff0000"), //纯色
              },
              label: {
                text: item.warningCount+'次',
                font: "16px PingFangSC-Regular",
                backgroundColor: Cesium.Color.fromCssColorString("#ffc400").withAlpha(1),
                showBackground: true,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                fillColor: Cesium.Color.fromCssColorString("#ffffff"),
                outlineWidth: 0,
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-20, -50), //偏移量
                disableDepthTestDistance: 1000000000,
                classificationType: Cesium.ClassificationType.BOTH,
              },
              billboard: {
                image: '/images/crowd/crowd_' + item.deviceType + '.png',
                width: 45,
                height: 45,
                color: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
                disableDepthTestDistance: 1000000000,
                heightReference: Cesium.HeightReference.NONE,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                classificationType: Cesium.ClassificationType.BOTH,
              },
            });


          });
        }
      },
        showCesiumMarker(item) {
            let _this = this;

            let iconObj = {
                src: "",
                width: 45,
                height: 45,
            };

            let list = [
                {
                    id: "company_01",
                    info: {
                        type: "company",
                    },
                    position: {
                        lon: 118.76717398585708,
                        lat: 32.10762875410027,
                        height: 55,
                    },
                    text: "",
                    icon: iconObj,
                },
                {
                    id: "company_02",
                    info: {
                        type: "company",
                    },
                    position: {
                        lon: 118.7675420893485,
                        lat: 32.10799468877504,
                        height: 55,
                    },
                    text: "",
                    icon: iconObj,
                },
            ];

            switch (item.type) {
                case "company":
                    iconObj.src = "/images/company.png";
                    this.changeCesiumMarkerList(list);
                    break;
                case "CAMERA":
                    iconObj.src = "/images/video.png";
                    this.$getApi(api.getDotList, {
                        type: item.type,
                        page: 1,
                        size: 1000,
                    }).then((res) => {
                        if (1 == res.code) {
                            _this.changeCesiumMarkerList(
                                res.data.dotList.map((item) => {
                                    return {
                                        id: item.id,
                                        info: {
                                            ...item,
                                        },
                                        position: {
                                            lon: item.lon,
                                            lat: item.lat,
                                            height: item.height,
                                        },
                                        text: "",
                                        icon: iconObj,
                                    };
                                })
                            );
                        }
                    });
                    break;
            }
        },
        getContentList() {
            let { pajkGetApi, pajkData } = this;
            this.safelyList[0].num = pajkGetApi
                ? pajkData.community_info_residents * 1
                : 0;
            this.safelyList[1].num = pajkGetApi
                ? pajkData.community_info_building * 1
                : 0;
            this.safelyList[2].num = pajkGetApi
                ? pajkData.company_count * 1
                : 0;
            this.safelyList[3].num = pajkGetApi
                ? pajkData.nine_small_count * 1
                : 0;
            this.safelyList[4].num = pajkGetApi
                ? pajkData.welfare_count * 1
                : 0;
            this.safelyList[5].num = pajkGetApi
                ? pajkData.fire_count * 1
                : 0;

            // this.contentList[1].number = 154;
        },
    },
};
