
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";
import axios from "axios";
import store from "@/store";
import * as Cesium from 'cesium';

export default {
    name: "componentDemo",
    components: {
        TitleText,
        Number,
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        msbzGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
            }
        },
    },
    data() {
        return {
            iconList: [
                {
                    icon: "iconsos",
                },
                {
                    icon: "iconyichangtongji",
                },
            ],
            numberStyle: {
                fontSize: 32,
                height: 32,
                width: 26,
                color: "#2AF3FF",
                fontFamily: "DigitalReadoutThickUpright",
            },
          iotShow: false
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getCmpData();
        }
    },
    methods: {
        getCmpData() {
            let { msbzData, iconList } = this;
            let newList = [];

            msbzData.homeCare.forEach((item, index) => {
                newList.push({
                    ...iconList[index],
                    title: item.key,
                    value: parseInt(item.value),
                });
            });

            this.iconList = newList;
        },
      get_device_list(index){
          let that = this;

          if(that.iotShow){
            window.cesiumViewer.entities.removeAll();
            that.iotShow = false;
            return;
          }


          let iotType;
          if (index === 0){
            iotType = 7;
          } else if (index === 1){
            iotType = 4;
          }else {
            return;
          }
          let params = {
            iotType: iotType,
            org_id:  store.state.publicInfo.nowOrgId
          }
        axios.get('/api_btq/getIotDeviceList', {params: params}).then(res=>{
          console.log(res.data.data.iotList)
          if(res.data && res.data.data && res.data.data.iotList){
            that.add_marker(res.data.data.iotList)
            that.iotShow = true;
          }
        })
      },
      //添加标注
      add_marker(data){
        //隐藏其他所有的模型
        window.cesiumViewer.entities.removeAll();
        if (data.length > 0) {
          data.forEach((item) => {
            //console.log(item);
            item.longitude = parseFloat(item.longitude)
            item.latitude = parseFloat(item.latitude)
            //先画线
            if(!item.height){
              item.height = 20
            }
            let linePositions = [];
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, item.height));
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude,  item.height + 50));

            window.cesiumViewer.entities.add({
              id: item.iotId,
              info: 'lively',
              type: "iot",
              position: Cesium.Cartesian3.fromDegrees(
                  item.longitude,
                  item.latitude,
                  item.height+50
              ),
              polyline: {
                positions: linePositions,
                width: 2,
                material: Cesium.Color.fromCssColorString("#ff0000"), //纯色
              },
              label: {
                text: item.warningCount+'次',
                font: "16px PingFangSC-Regular",
                backgroundColor: Cesium.Color.fromCssColorString("#ffc400").withAlpha(1),
                showBackground: true,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                fillColor: Cesium.Color.fromCssColorString("#ffffff"),
                outlineWidth: 0,
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-20, -50), //偏移量
                disableDepthTestDistance: 1000000000,
                classificationType: Cesium.ClassificationType.BOTH,
              },
              billboard: {
                image: '/images/crowd/crowd_' + item.deviceType + '.png',
                width: 45,
                height: 45,
                color: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
                disableDepthTestDistance: 1000000000,
                heightReference: Cesium.HeightReference.NONE,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                classificationType: Cesium.ClassificationType.BOTH,
              },
            });


          });
        }
      }
    },

};
